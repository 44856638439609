import { Card } from "react-bootstrap";

interface FaqCardProps {
  question: string;
  answer: string;
}

export default function FaqCard(props: FaqCardProps) {

  return (
    <Card>
      <Card.Header><h4 className="card-header-title">{props.question}</h4></Card.Header>
      <Card.Body>
        <p className="mb-0">{props.answer} </p>
      </Card.Body>
    </Card>
  );


}