import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
//import { WalletLinkConnector } from '@web3-react/walletlink-connector'
//import { LedgerConnector } from '@web3-react/ledger-connector'

//const POLLING_INTERVAL = 12000
//const RPC_URLS: { [chainId: number]: string } = {
//  1: process.env.RPC_URL_1 as string,
//  4: process.env.RPC_URL_4 as string
//}

export const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 31337, 1337] })

export const network = new NetworkConnector({
  urls: { 31337: "http://127.0.0.1:8545/", 1: "https://eth-mainnet.alchemyapi.io/v2/d8bS6AkSFaAq0gKGhBTNSZBjDjtx9L9u" },
  defaultChainId: 1
})

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: "https://mainnet.infura.io/v3/84842078b09946638c03157f83405213" },
  qrcode: true
})

//export const walletlink = new WalletLinkConnector({
//  url: RPC_URLS[1],
//  appName: 'web3-react example',
//  supportedChainIds: [1, 3, 4, 5, 42, 10, 137, 69, 420, 80001]
//})

//export const ledger = new LedgerConnector({ chainId: 1, url: RPC_URLS[1], pollingInterval: POLLING_INTERVAL })
