import { formatEther } from "@ethersproject/units";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BigNumber } from "ethers";
import { Button, Card, Col, Row } from "react-bootstrap";
import MyToken from "../util/MyToken";

interface BalanceCardProps {
  header: string;
  tokens: MyToken[];
  withdrawing: number[];
  onWithdraw: (tokenId: number, amount: BigNumber) => void;
}

export default function BalanceCard(props: BalanceCardProps) {

  return (
    <Card>
      <Card.Header><h4 className="card-header-title">{props.header}</h4></Card.Header>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
          <div className="table-responsive">
            <table className="table-nowrap card-table table table-sm">
              <thead>
                <tr>
                  <th colSpan={1} title="Toggle SortBy" className="is-sortable" style={{cursor: "pointer"}}>Token ID</th>
                  <th colSpan={1} title="Toggle SortBy" className="is-sortable" style={{cursor: "pointer"}}>Rewards</th>
                  <th colSpan={1} title="Toggle SortBy" className="is-sortable" style={{cursor: "pointer"}}></th>
                </tr>
              </thead>
              <tbody>
                { props.tokens.map((token) => {
                  return (<tr key={token.tokenId}>
                            <td>{token.tokenId}</td>
                            <td>
                              {formatEther(token.balance)}
                              <FontAwesomeIcon className="ms-1" icon={["fab", "ethereum"]} />
                            </td>
                            <td>
                              <Button size="sm" variant="primary" onClick={() => props.onWithdraw(token.tokenId, token.balance)} disabled={token.balance.isZero()}>{props.withdrawing.includes(token.tokenId) ?<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span>&nbsp;</span></> : <></>}{props.withdrawing.includes(token.tokenId) ? "Withdrawing..." : "Withdraw"}</Button>
                            </td>                        
                          </tr>);

                })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );


}