import { Col, Row } from "react-bootstrap";
import FaqCard from "./cards/FaqCard";

export default function StaticContent() {
  return (
    <>
    <Row className="mt-5">
      <Col md="6"><FaqCard question="What is this thing?" answer="An NFT that you mint by making a visual contribution. Each time someone modifies the art, they receive a token, and the art is updated for all other token holders." /></Col>
      <Col md="6"><FaqCard question="How much does it cost to mint?" answer="The price to mint increases linearly over time to help prevent vandalism, but the amount paid to mint a new token is divided equally amongst all previous token holders, so each artist is rewarded by each subsequent artist." /></Col>
    </Row>
    <Row className="mt-2">
      <Col md="6"><FaqCard question="Where is the data stored?" answer="The entire NFT is stored on-chain." /></Col>
      <Col md="6"><FaqCard question="Are rewards divided per-token or per-artist?" answer="Rewards are divided per-token, so if you make multiple contributions you will receive rewards for each token." /></Col>
    </Row>
    <Row className="mt-2">
      <Col md="6"><FaqCard question="How do I withdraw rewards?" answer="Connect your wallet and scroll to the 'members only' section at the bottom of the page." /></Col>
      <Col md="6"><FaqCard question="What are Autonomous Graphics 'forks'?" answer="Each token holder can run and configure their own sub 'fork' of Autonomous Graphics, where the starting image for the fork is the image at the time the Autonomous Graphics token was minted. The token holder can set the base price and price increment interval for the fork, which determines how much it costs to mint contributions to the fork, and how often the price to mint increases by that amount." /></Col>
    </Row>
    <Row className="mt-2">
      <Col md="6"><FaqCard question="What are the limits on fork prices?" answer="The base price can not be 0, and once configured it can not be lowered -- only raised." /></Col>
    </Row>
  </>
  );
}