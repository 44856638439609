import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

interface RevisionsCardProps {
  count: number;
}

export default function RevisionsCard(props: RevisionsCardProps) {

  const {forkId} = useParams();

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">Contributions {forkId != null && " on this fork"}</h6>
            <span className="h2 mb-0">{props.count} </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );


}