import CSS from 'csstype';
import React, {useState, useEffect, RefObject} from 'react';
import Element from '../elements/Element';
import { getViewPortClickPosition } from '../util/ViewPort';

interface HoverBoxProps {
  element  : Element
  canvas   : RefObject<SVGSVGElement>
  scrollX  : number
  scrollY  : number
  onMouseOut : () => void;
  onUpdate : (element : Element) => void;
  onSelect : () => void;
  onEdit   : () => void;
}

interface AnchorProps {
  width: Number
  height: Number
  onMouseDown: (event : React.MouseEvent) => void;
  onMouseUp: (event : React.MouseEvent) => void;
}

const hoverBoxStyle : CSS.Properties = {
  position: 'absolute',
  border: '2px solid #dedede',
  zIndex: 9999999
}

const scaleAnchorStyle : CSS.Properties = {
  position: 'absolute',
  borderRight: '2px solid #dedede',
  borderBottom: '2px solid #dedede',
  zIndex: '-1'
}

const rotateAnchorStyle : CSS.Properties = {
  position: 'absolute',
  borderRight: '2px solid #dedede',
  borderTop: '2px solid #dedede',
  zIndex: -1,
  borderTopRightRadius: '4px'
}

function ScaleAnchor(props: AnchorProps) {

  const [isHover, setHover] = useState(false);

  function anchorStyle() {
    return {...scaleAnchorStyle,
      top: props.height.valueOf() - 4,
      left: props.width.valueOf() - 4,
      width: 10,
      height: 10,
      borderColor: isHover ? 'gray' : '#dedede'
    }
  }

  return (<div className="scale-anchor" style={anchorStyle()} onPointerOver={() => setHover(true)} onPointerOut={() => setHover(false)} onMouseDown={props.onMouseDown} onMouseUp={props.onMouseUp}/>);
}

function RotateAnchor(props: AnchorProps) {

  const [isHover, setHover] = useState(false);

  function anchorStyle() {
    return {...rotateAnchorStyle,
            top: -8,
            left: props.width.valueOf() - 4,
            width: 10,
            height: 10,
            borderColor: isHover ? 'gray' : '#dedede'}
  }

  return (<div className="rotate-anchor" style={anchorStyle()} onPointerOver={() => setHover(true)} onPointerOut={() => setHover(false)} onMouseDown={props.onMouseDown} onMouseUp={props.onMouseUp} />);
}

export default function HoverBox(props : HoverBoxProps) {

  const [isDragging, setDragging]   = useState(false);
  const [isResizing, setResizing]   = useState(false);
  const [isRotating, setRotating]   = useState(false);

  const [dragOffset, setDragOffset]     = useState([0, 0]);
  const [resizeOffset, setResizeOffset] = useState([0, 0]);
  const [rotateOffset, setRotateOffset] = useState([0, 0]);

  useEffect(() => {
    if (isDragging || isResizing || isRotating) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging, isResizing, isRotating]);

  function getViewPortScale(dimension: number) {
    let rootPoint = props.canvas.current!.createSVGPoint();
    let point     = props.canvas.current!.createSVGPoint();

    point.x   = dimension;
    point.y   = 0;

    rootPoint.x = 0;
    rootPoint.y = 0;

    let screenCoordinate = point.matrixTransform(props.canvas.current!.getScreenCTM()!).x;
    let rootCoordinate   = rootPoint.matrixTransform(props.canvas.current!.getScreenCTM()!).x;

    return screenCoordinate - rootCoordinate;
  }

  function getElementAbsoluteXPosition() {
    let point = props.canvas.current!.createSVGPoint();
    point.x = props.element.x.valueOf();
    point.y = props.element.y.valueOf();

    return point.matrixTransform(props.canvas.current!.getScreenCTM()!).x + props.scrollX;
  }

  function getElementAbsoluteYPosition() {
    let point = props.canvas.current!.createSVGPoint();
    point.x = props.element.x.valueOf();
    point.y = props.element.y.valueOf();

    return point.matrixTransform(props.canvas.current!.getScreenCTM()!).y + props.scrollY;
    //return props.canvas.current!.getBoundingClientRect().y;
  }

  function handleMouseMove(event : MouseEvent) {
    if (isDragging) {
      const [clientX, clientY] = getViewPortClickPosition(props.canvas.current!, event);
      const mouseX = clientX - dragOffset[0];
      const mouseY = clientY - dragOffset[1];

      props.onUpdate(props.element.withUpdatedCoordinates(mouseX, mouseY));
    } else if (isResizing) {
      const [clientX, clientY] = getViewPortClickPosition(props.canvas.current!, event);

      const targetX = clientX - resizeOffset[0];
      const targetY = clientY - resizeOffset[1];

      const width  = targetX - props.element.x.valueOf();
      const height = targetY - props.element.y.valueOf();

      props.onUpdate(props.element.withUpdatedDimensions(width, height));
    } else if (isRotating) {
      const [clientX, clientY] = getViewPortClickPosition(props.canvas.current!, event);

      const angle = Math.atan2(rotateOffset[0] + (props.element.width.valueOf()) / 2 - clientX,
                               rotateOffset[1] + (props.element.height.valueOf()) / 2 - clientY);

      const asDegree = angle * 180 / Math.PI;
      const rotation = (asDegree + 45) * -1;

      props.onUpdate(props.element.withUpdatedRotation(rotation));
    }
  }

  function handleMouseUp(event : MouseEvent) {
    setDragging(false);
    setResizing(false);
    setRotating(false);
  }

  function handleStartDrag(event: React.MouseEvent) {
    props.onSelect();

    setDragging(true);
    setResizing(false);
    setRotating(false);

    const [clientX, clientY] = getViewPortClickPosition(props.canvas.current!, event);

    const offsetX = clientX - props.element.x.valueOf();
    const offsetY = clientY - props.element.y.valueOf();

    setDragOffset([offsetX, offsetY]);
  }

  function handleStartResize(event: React.MouseEvent) {
    event.stopPropagation();

    setResizing(true);
    setDragging(false);
    setRotating(false);

    const [clientX, clientY] = getViewPortClickPosition(props.canvas.current!, event);

    const offsetX = clientX - (props.element.x.valueOf() + props.element.width.valueOf());
    const offsetY = clientY - (props.element.y.valueOf() + props.element.height.valueOf());

    setResizeOffset([offsetX, offsetY]);
  }

  function handleStartRotate(event: React.MouseEvent) {
      event.stopPropagation();

      setRotating(true);
      setResizing(false);
      setDragging(false);

//      setRotateOffset([getElementAbsoluteXPosition(),
//                       getElementAbsoluteYPosition()]);
    setRotateOffset([props.element.x.valueOf(), props.element.y.valueOf()])
  }

  return (<div className="hoverBox" 
               style={{...hoverBoxStyle, 
                       top: getElementAbsoluteYPosition()-2, 
                       left: getElementAbsoluteXPosition()-2, 
                       width: getViewPortScale(props.element.width.valueOf())+4, 
                       height: getViewPortScale(props.element.height.valueOf())+4,
                       transform: `rotate(${props.element.rotation.valueOf()}deg)`}}                                            
               onMouseLeave={() => {if (!isDragging && !isResizing && !isRotating) props.onMouseOut();}}
               onMouseDown={handleStartDrag}
               onDoubleClick={() => props.onEdit()}
               >

              { props.element.supportsScale && 
              <ScaleAnchor width={getViewPortScale(props.element.width.valueOf())}
                           height={getViewPortScale(props.element.height.valueOf())}
                           onMouseDown={handleStartResize}
                           onMouseUp={() => setResizing(false)}
              />
              }

              <RotateAnchor width={getViewPortScale(props.element.width.valueOf())}
                            height={getViewPortScale(props.element.height.valueOf())}
                            onMouseDown={handleStartRotate}
                            onMouseUp={() => setRotating(false)}

              />

          </div>
                 )


}