import { PolygonPointType } from "./PolygonElement";

export default class Element {

  constructor(readonly type: string, 
              readonly _x : Number, readonly _y : Number, 
              readonly _width : Number, readonly _height : Number,
              readonly radius : Number, readonly rotation : Number,
              readonly fillColor : string, readonly strokeColor : string,
              readonly strokeWidth: Number) 
  {
  }

  get x() : Number {
    return this._x;
  }

  get y() : Number {
    return this._y;
  }

  get width() : Number {
    return this._width;
  }

  get height() : Number {
    return this._height;
  }

  get supportsRadius() : boolean {
    return true;
  }

  get supportsScale() : boolean {
    return true;
  }

  get editing() : boolean {
    return false;
  }

  get deletable() : boolean {
    return true;
  }

  getPointType(index : number) : PolygonPointType {
    throw Error("not supported");
  }

  get selectedPoint() : number {
    return -1;
  }

  withUpdatedDimensions(width : Number, height : Number) : Element {
    return new Element(this.type, this.x, this.y, width, height, this.radius, this.rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  withUpdatedCoordinates(x : Number, y : Number) : Element {
    return new Element(this.type, x, y, this.width, this.height, this.radius, this.rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  withUpdatedRotation(rotation : Number) : Element {
    return new Element(this.type, this.x, this.y, this.width, this.height, this.radius, rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  withUpdatedFillColor(fillColor : string) : Element {
    return new Element(this.type, this.x, this.y, this.width, this.height, this.radius, this.rotation, fillColor, this.strokeColor, this.strokeWidth);
  }

  withUpdatedStrokeColor(strokeColor : string) : Element {
    return new Element(this.type, this.x, this.y, this.width, this.height, this.radius, this.rotation, this.fillColor, strokeColor, this.strokeWidth);
  }
  
  withUpdatedStrokeWidth(strokeWidth : Number) : Element {
    return new Element(this.type, this.x, this.y, this.width, this.height, this.radius, this.rotation, this.fillColor, this.strokeColor, strokeWidth);
  }

  withUpdatedRadius(radius : Number) : Element {
    return new Element(this.type, this.x, this.y, this.width, this.height, radius, this.rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  withEditing(editing : boolean) : Element {
    return this;
  }

  withPointType(index : number, type : PolygonPointType) : Element {
    throw Error("assertion");
  }
}