import Element from "./Element";

export default class PolygonElement extends Element {

  constructor(readonly points: PolygonElementPoint[], 
              readonly _editing: boolean, readonly closed: boolean, 
              rotation: Number, fillColor: string, strokeColor: string, strokeWidth: Number) 
  {
    super("polygon", 0, 0, 0, 0, 0, rotation, fillColor, strokeColor, strokeWidth);
  }

  static newDefault(points: PolygonElementPoint[]) {
    return new PolygonElement(points, false, false, 0, "#ffffff", "#000000", 1);
  }

  withUpdatedCoordinates(x : Number, y: Number) : PolygonElement {
    const diffX = x.valueOf() - this.x.valueOf();
    const diffY = y.valueOf() - this.y.valueOf();

    const translatedPoints = this.points.map((point) => {
      return {...point, x: point.x.valueOf() + diffX, y: point.y.valueOf() + diffY, 
              cx1: point.cx1 ? point.cx1.valueOf() + diffX : point.cx1,
              cy1: point.cy1 ? point.cy1.valueOf() + diffY : point.cy1,
              cx2: point.cx2 ? point.cx2.valueOf() + diffX : point.cx2,
              cy2: point.cy2 ? point.cy2.valueOf() + diffY : point.cy2};
    })

    return new PolygonElement(translatedPoints, this.editing, this.closed, this.rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  withUpdatedDimensions(width : Number, height : Number) : PolygonElement {
    throw Error("Unable to scale path");
  }

  withUpdatedRotation(rotation : Number) : PolygonElement {
    return new PolygonElement(this.points, this.editing, this.closed, rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  withUpdatedFillColor(fillColor : string): PolygonElement {
    return new PolygonElement(this.points, this.editing, this.closed, this.rotation, fillColor, this.strokeColor, this.strokeWidth);
  }

  withUpdatedStrokeColor(strokeColor : string) : PolygonElement {
    return new PolygonElement(this.points, this.editing, this.closed, this.rotation, this.fillColor, strokeColor, this.strokeWidth);
  }

  withUpdatedStrokeWidth(strokeWidth : Number) : PolygonElement {
    return new PolygonElement(this.points, this.editing, this.closed, this.rotation, this.fillColor, this.strokeColor, strokeWidth);
  }

  withUpdatedRadius(radius : Number) : PolygonElement {
    throw Error("Unable to update radius of path");
  }

  withEditing(editing: boolean) : PolygonElement {
    return new PolygonElement(this.points, editing, this.closed, this.rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  withPointType(index : number, type : PolygonPointType) : PolygonElement {
    const points = this.points.map((point, i) => {
      if (index === i) return {...point, type: type};
      else             return point;
    });

    return new PolygonElement(points, this.editing, this.closed, this.rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  withSelectedPoint(index : number) : PolygonElement {
    const points = this.points.map((point, i) => {
      if (index === i) return {...point, selected: true};
      else             return {...point, selected: false};
    });

    return new PolygonElement(points, this.editing, this.closed, this.rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  withPoints(points : PolygonElementPoint[]) {
    return new PolygonElement(points, this.editing, this.closed, this.rotation, this.fillColor, this.strokeColor, this.strokeWidth);
  }

  getPointType(index : number): PolygonPointType {
    return this.points[index].type;
  }

  get selectedPoint() : number {
    return this.points.map(point => point.selected).indexOf(true);
  }

  get x(): Number {
    return Math.min(...this.points.map((point) => point.x.valueOf()));
  }

  get y(): Number {
    return Math.min(...this.points.map((point) => point.y.valueOf()));
  }

  get width(): Number {
    const maxX = Math.max(...this.points.map((point) => point.x.valueOf()));
    return maxX - this.x.valueOf();
  }

  get height(): Number {
    const maxY = Math.max(...this.points.map((point) => point.y.valueOf()));
    return maxY - this.y.valueOf();
  }

  get supportsRadius() : boolean {
    return false;
  }

  get supportsScale() : boolean {
    return false;
  }

  get editing() : boolean {
    return this._editing;
  }

  get deletable() : boolean {
    return !this.editing || this.selectedPoint === -1 || this.points.length < 2;
  }

}

export enum PolygonPointType {
  LINE, CURVE
}

export interface PolygonElementPoint {
  x: Number;
  y: Number;

  cx1?: Number;
  cy1?: Number;

  cx2?: Number;
  cy2?: Number;

  type: PolygonPointType;

  selected: boolean;
}