import { Card, Col, Row } from "react-bootstrap";

interface ForkedRevisionsCardProps {
  count: number;
}

export default function ForkedRevisionsCard(props: ForkedRevisionsCardProps) {

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">Number of forked contributions</h6>
            <span className="h2 mb-0">{props.count} </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );


}