import { BigNumber } from "@ethersproject/bignumber";
import { formatEther } from "@ethersproject/units";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

interface FundedCardProps {
  count: BigNumber;
}

export default function FundedCard(props: FundedCardProps) {

  const {forkId} = useParams();

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">Total paid to artists {forkId != null && " on this fork"}</h6>
            <span className="h2 mb-0">{formatEther(props.count)} </span>
            <FontAwesomeIcon className="pb-1" icon={["fab", "ethereum"]} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );


}