import CSS from 'csstype';
import React, {useState} from 'react';
import { Row, Col, InputGroup, FormControl, Card, ListGroup, Button } from 'react-bootstrap';
import Element from './elements/Element';
import { SketchPicker } from 'react-color'
import { PolygonPointType } from './elements/PolygonElement';

interface ShapeInspectorProps {
  element : Element;
  onElementChanged: (element : Element) => void;
  onSendBackward: () => void;
  onBringForward: () => void;
}

export default function ShapeInspector(props : ShapeInspectorProps) {

  const [isFillPickerVisible, setFillPickerVisible]     = useState(false);
  const [isStrokePickerVisible, setStrokePickerVisible] = useState(false);

  const swatchStyle : CSS.Properties = {
    padding: "5px",
    background: "#fff",
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer'
  }

  const colorStyle : CSS.Properties = {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  }

  const popoverStyle : CSS.Properties = {
    position: 'absolute',
    zIndex: '2',
  }

  const coverStyle : CSS.Properties = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }
  
  return (
    <div className="shapeInspetor">
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col xs="3">
              <p className="text-end">Size</p>
            </Col>
            <Col xs="4">
              <InputGroup size="sm" >
                <InputGroup.Text id="width">w</InputGroup.Text>
                <FormControl readOnly={!props.element.supportsScale} type="number" value={props.element.width.toString()} aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(value) => props.onElementChanged(props.element.withUpdatedDimensions(Number(value.target.value), props.element.height))} />
              </InputGroup>
            </Col>
            <Col xs="4">
              <InputGroup size="sm" >
                <InputGroup.Text id="width">h</InputGroup.Text>
                <FormControl readOnly={!props.element.supportsScale} type="number" value={props.element.height.toString()} onChange={(value) => props.onElementChanged(props.element.withUpdatedDimensions(props.element.width, Number(value.target.value)))} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="3">
              <p className="text-end">Position</p>
            </Col>
            <Col xs="4">
              <InputGroup size="sm" >
                <InputGroup.Text id="width">x</InputGroup.Text>
                <FormControl type="number" value={props.element.x.toString()} onChange={(value) => props.onElementChanged(props.element.withUpdatedCoordinates(Number(value.target.value), props.element.y))} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
            <Col xs="4">
              <InputGroup size="sm" >
                <InputGroup.Text id="width">y</InputGroup.Text>
                <FormControl type="number" value={props.element.y.toString()} onChange={(value) => props.onElementChanged(props.element.withUpdatedCoordinates(props.element.x, Number(value.target.value)))} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="3">
              <p className="text-end">Rotation</p>
            </Col>
            <Col xs="4">
              <InputGroup size="sm" >
                <InputGroup.Text id="width">angle</InputGroup.Text>
                <FormControl type="number" value={props.element.rotation.toString()} onChange={(value) => props.onElementChanged(props.element.withUpdatedRotation(Number(value.target.value)))} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      { props.element.type === "polygon" && props.element.selectedPoint !== -1 && 
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col xs="3">
                  <p className="text-end">Type</p>
                </Col>
                <Col xs="4" className="text-start">
                  <ListGroup horizontal>
                    <ListGroup.Item active={props.element.getPointType(props.element.selectedPoint) === PolygonPointType.LINE} onClick={() => props.onElementChanged(props.element.withPointType(props.element.selectedPoint, PolygonPointType.LINE))} action>Line</ListGroup.Item>
                    <ListGroup.Item disabled={props.element.selectedPoint === 0} active={props.element.getPointType(props.element.selectedPoint) === PolygonPointType.CURVE} onClick={() => props.onElementChanged(props.element.withPointType(props.element.selectedPoint, PolygonPointType.CURVE))} action>Curve</ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>

            </Card.Body>
          </Card>
        }

      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col xs="3">
              <p className="text-end">Fill</p>
            </Col>
            <Col xs="4" className="text-start">
              <div style={swatchStyle} onClick={() => setFillPickerVisible(!isFillPickerVisible)}>
                <div style={{...colorStyle, background: props.element.fillColor}} />
              </div>
              { isFillPickerVisible ? <div style={popoverStyle}>
                                        <div style={coverStyle} onClick={() => setFillPickerVisible(false) }/>
                                        <SketchPicker color={props.element.fillColor} onChange={(color) => {props.onElementChanged(props.element.withUpdatedFillColor(color.hex))}} />
                                      </div> : null 
              }
            </Col>
          </Row>

          <Row>
            <Col xs="3">
              <p className="text-end">Stroke</p>
            </Col>
            <Col xs="2" className="text-start">
              <div style={swatchStyle} onClick={() => setStrokePickerVisible(!isStrokePickerVisible)}>
                  <div style={{...colorStyle, background: props.element.strokeColor}} />
                </div>
                { isStrokePickerVisible ? <div style={popoverStyle}>
                                          <div style={coverStyle} onClick={() => setStrokePickerVisible(false) }/>
                                          <SketchPicker color={props.element.strokeColor} onChange={(color) => {props.onElementChanged(props.element.withUpdatedStrokeColor(color.hex))}} />
                                        </div> : null 
                }
            </Col>
          </Row>

          <Row>
            <Col xs="3">
            </Col>
            <Col xs="4">
              <InputGroup size="sm" >
                <InputGroup.Text id="strokeWidth">width</InputGroup.Text>
                <FormControl type="number" value={props.element.strokeWidth.toString()} onChange={(value) => props.onElementChanged(props.element.withUpdatedStrokeWidth(Number(value.target.value)))} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
            <Col xs="4">
              <InputGroup size="sm" >
                <InputGroup.Text id="radius">radius</InputGroup.Text>
                <FormControl readOnly={!props.element.supportsRadius} type="number" value={props.element.radius.toString()} onChange={(value) => props.onElementChanged(props.element.withUpdatedRadius(Number(value.target.value)))} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>

          </Row>


        </Card.Body>
      </Card>

      <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col xs="3">
                <p className="text-end">Arrange</p>
              </Col>
              <Col xs="6" className="text-start">
                <div className="d-grid gap-2">
                  <Button onClick={() => props.onBringForward()} variant="outline-secondary" size="sm">
                    <i className="bi bi-back"></i> Bring forward                
                  </Button>
                  <Button onClick={() => props.onSendBackward()} variant="outline-secondary" size="sm">
                    <i className="bi bi-back"></i> Send back
                  </Button>
                </div>
              </Col>
            </Row>

          </Card.Body>
        </Card>




    </div>
  );
}