import {useEffect} from 'react';
import { useWeb3React } from '@web3-react/core';
import { network } from './connectors';

export const useEthNetwork = () => {
  const { active, library, activate } = useWeb3React("network");

  useEffect(() => {
    async function connectToWeb3Network() {
      if (!active) await activate(network);
    }

    console.log("useEthNetwork: " + active);
    setTimeout(connectToWeb3Network, 1);
  }, [active, activate]);

  return [active, library];
};

export async function fetchJson<T>(request: string): Promise<T> {
  if (request.startsWith("ipfs://ipfs")) {
    request = "https://ipfs.io/ipfs" + request.substr("ipfs://ipfs".length);
  } else if (request.startsWith("ipfs://")) {
    request = "https://ipfs.io/ipfs/" + request.substr("ipfs://".length);
  }

  if (request.startsWith("https://api.artblocks.io/token")) {
    request = "https://token.artblocks.io" + request.substr("https://api.artblocks.io/token".length);
  }

  const response = await fetch(request);
  const body     = await response.json();
  return body;
}
