import { useWeb3React } from "@web3-react/core";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useCallback, useEffect, useState } from 'react';
import ConnectWalletForm from "./ConnectWalletForm";
import { AutonomousSubArt, AutonomousSubArt__factory, CollectiveCanvas, CollectiveCanvas__factory } from "./typechain";
import { CANVAS_CONTRACT_ADDRESS, SUB_ART_CONTRACT_ADDRESS } from "./Constsnts";
import { toUtf8Bytes } from "@ethersproject/strings";
import { BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useEthNetwork } from "./NetworkConnector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "ts-debounce";
import {dispatch} from 'use-bus';

interface MintButtonProps {
  serializedLayer: string;
  parentId: string | undefined;
  isMintable: boolean;
}

export default function MintButton(props: MintButtonProps) {

  const [showModal, setShowModal]                          = useState(false);
  const [estimatedGas, setEstimatedGas]                    = useState(BigNumber.from(0));
  const { active : signerActive, library : signerLibrary } = useWeb3React()
  const [active, library ]                                 = useEthNetwork();
  const [isMinting, setIsMinting]                          = useState(false);
  const [mintingStatus, setMintingStatus]                  = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateEstimate = useCallback(debounce(async (serializedLayer: string, library: any) => {
    const gasPrice        = BigNumber.from(100000000000);//await library.getSigner().getGasPrice();

    if (props.parentId == null) {
      const contract : CollectiveCanvas = CollectiveCanvas__factory.connect(CANVAS_CONTRACT_ADDRESS, library);

      const price           = await contract.currentPriceToMint();
      const estimatedGas    = await contract.estimateGas.mint(toUtf8Bytes(serializedLayer), {value: price, from: "0x73bceb1cd57c711feac4224d062b0f6ff338501e"});

      setEstimatedGas(estimatedGas.mul(gasPrice));
    } else {
      const contract : AutonomousSubArt = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library);

      const price        = await contract.currentPriceToMint(props.parentId);
      const estimatedGas = await contract.estimateGas.mint(BigNumber.from(props.parentId), toUtf8Bytes(serializedLayer), {value: price, from: "0x73bceb1cd57c711feac4224d062b0f6ff338501e"});

      setEstimatedGas(estimatedGas.mul(gasPrice));
    }
  }, 1000), []);

  useEffect(() => {
    if (active && props.isMintable) {
      console.log("Callign with layer: " + props.serializedLayer);
      updateEstimate(props.serializedLayer, library);
    }
  }, [active, updateEstimate, library, props.serializedLayer, props.parentId, props.isMintable]);

  async function onMint() {
    if (isMinting) return;
    setIsMinting(true);
    setMintingStatus("");

    console.log("Would mint layer: " + props.serializedLayer);
    try {
      if (props.parentId == null) {
        const contract : CollectiveCanvas = CollectiveCanvas__factory.connect(CANVAS_CONTRACT_ADDRESS, signerLibrary.getSigner());
        const price   = await contract.currentPriceToMint();
        const token   = await contract.mint(toUtf8Bytes(props.serializedLayer), {value: price});
        await token.wait(1);
      } else {
        const contract : AutonomousSubArt = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, signerLibrary.getSigner());
        const price   = await contract.currentPriceToMint(props.parentId);
        const token   = await contract.mint(BigNumber.from(props.parentId), toUtf8Bytes(props.serializedLayer), {value: price});
        await token.wait(1);
      }

      dispatch('MintButton/onMinted');      
    } catch (error) {
      console.error(error);
      setMintingStatus("Minting failed!");
    }

    setIsMinting(false);
  }

  return ( 
    <Row>
      <Col>
        <div className="d-grid gap-2">
          <div className="mb-1">
            <span className="text-muted h4">Estimated gas cost: {formatEther(estimatedGas)} </span>
            <FontAwesomeIcon icon={["fab", "ethereum"]} style={{color: "#95aac9"}}/>
          </div>

        { mintingStatus !== "" && <h4 className="text-danger">{mintingStatus}</h4>}

        { signerActive ? props.isMintable ? <Button size="lg" variant="primary" onClick={onMint} disabled={props.serializedLayer === ""}>{isMinting ?<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span>&nbsp;</span></> : <></>}{isMinting ? "Minting..." : "Mint" + (props.parentId != null ? " on this fork" : "")}</Button> : 
                                            <Button size="lg" variant="secondary" disabled>Fork is pending</Button> :
                                            <Button size="lg" variant="secondary" onClick={() => setShowModal(true)}>Connect wallet</Button>
        }
        </div>

        <Modal show={showModal && !signerActive} onHide={() => setShowModal(false)} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Connect wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ConnectWalletForm />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    </Row>
    )

}