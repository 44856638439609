import Element from './Element';

interface RectangleProps {
  element: Element;
  onMouseOver: () => void;
  onSelected: () => void;
}

export default function Rectangle(props : RectangleProps) {

  function width() {
    return props.element.width ?? 100;
  }

  function height() { 
    return props.element.height ?? 100;
  }

  function x() {
    return props.element.x ?? 0;
  }

  function y() {
    return props.element.y ?? 0;
  }

  function rotation() {
    return props.element.rotation ?? 0;
  }

  const centerX = (width().valueOf() / 2) + x().valueOf();
  const centerY = (height().valueOf() / 2) + y().valueOf();

  return (
    <rect x={x().toString()} 
          y={y().toString()} 
          width={width().toString() + "px"} 
          height={height().toString() + "px"}
          transform={"rotate(" + rotation() + " " + centerX + " " + centerY + ")"}
          fill={props.element.fillColor}
          stroke={props.element.strokeColor}
          strokeWidth={props.element.strokeWidth.valueOf()}
          rx={props.element.radius.valueOf()}
          onMouseOver={() => {props.onMouseOver()}}
          onMouseDown={() => {props.onSelected()}}
          />
  );

}
