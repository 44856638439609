import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TopForksCard from "./cards/TopForksCard";
import { FirestoreTopFork } from "./firestore/FirestoreTopFork";
import { converter } from "./firestore/FirestoreTopForks";
import db from "./util/Firebase";

export default function ForkLeaderBoard() {

  const [topForksByLayers, setTopForksByLayers] = useState<FirestoreTopFork[]>([]);
  const [topForksByFunds, setTopForksByFunds]   = useState<FirestoreTopFork[]>([]);
  const [topForksByCost, setTopForksByCost]     = useState<FirestoreTopFork[]>([]);

  useEffect(() => {
    onSnapshot(doc(db, "top-forks", "by-layers").withConverter(converter), (doc) => {
      setTopForksByLayers(doc.data()!.forks);
    });

    onSnapshot(doc(db, "top-forks", "by-funded").withConverter(converter), (doc) => {
      setTopForksByFunds(doc.data()!.forks);
    });

    onSnapshot(doc(db, "top-forks", "by-cheapest").withConverter(converter), (doc) => {
      setTopForksByCost(doc.data()!.forks);
    })
  }, []);

  return (
    <>
    <Row className={"mt-6"}>
      <Col lg="12">
        <h1 className="display-3 text-md-start">Explore the forks</h1>
        <p className="lead text-muted">Autonomous Art can take many different directions. Forks are collective sub-projects that start with a point in the history.</p>
      </Col>
    </Row>
    <Row className="mt-2">
      <Col md="12">
        <TopForksCard title="Top forks by contribution count" topForks={topForksByLayers} desc={true} funded={true}/>
      </Col>      
    </Row>
    <Row className="mt-5">
      <Col md="12">
        <TopForksCard title="Top forks by amount paid to artists" topForks={topForksByFunds} desc={true} funded={true}/>
      </Col>
    </Row>
    <Row className="mt-5">
      <Col md="12">
        <TopForksCard title="Top forks by cheapest price to mint" topForks={topForksByCost} desc={false} funded={false}/>
      </Col>
    </Row>

  </>    
  );


}