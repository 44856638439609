import { useEffect } from 'react'
import { injected } from '../connectors'
import { useWeb3React } from '@web3-react/core'

interface AutoConnectingInjectedProviderProps {
  children: JSX.Element
}

export default function AutoConnectingInjectedProvider(props: AutoConnectingInjectedProviderProps) {
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React()

  useEffect(() => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        if (isAuthorized && !networkActive && !networkError) {
          activateNetwork(injected)
        }
      })
      .catch((error) => {
        console.error(error);
      })
  }, [activateNetwork, networkActive, networkError])

  return props.children;
}