import { BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { Button, Card, Col, Row } from "react-bootstrap";
import useInputChange from "../editor/util/useInputChange";
import MyFork from "../util/MyFork";

interface ConfigureForkCardProps {
  myForks : MyFork[]
  onSave: (tokenId : number, basePrice : BigNumber, incrementInterval: number) => void;
  updating: number[];
}

export default function ConfigureForkCard(props: ConfigureForkCardProps) {

  const [input, handleInputChange]                  = useInputChange();

  function rawBasePriceFor(tokenId: number) {
    var price = input[tokenId + "-basePrice"] as BigNumber | null;
    
    if (price == null) price = props.myForks.find((fork) => fork.tokenId === tokenId)!.basePrice;

    return price;
  }

  function formattedBasePriceFor(tokenId: number) {
    return formatEther(rawBasePriceFor(tokenId));
  }

  function priceIncrementFor(tokenId: number) {
    var priceIncrement = input[tokenId + "-priceIncrement"] as number | null;

    if (priceIncrement == null) priceIncrement = props.myForks.find((fork) => fork.tokenId === tokenId)!.incrementInterval;

    return priceIncrement;
  }

  return (
    <Card>
      <Card.Header><h4 className="card-header-title">Configure forks</h4></Card.Header>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
          <div className="table-responsive">
            <table className="table-nowrap card-table table table-sm">
              <thead>
                <tr>
                  <th colSpan={1} title="Toggle SortBy" className="is-sortable" style={{cursor: "pointer"}}>Token ID</th>
                  <th colSpan={1} title="Toggle SortBy" className="is-sortable" style={{cursor: "pointer"}}>Base price</th>
                  <th colSpan={1} title="Toggle SortBy" className="is-sortable" style={{cursor: "pointer"}}>Price increment interval</th>
                  <th colSpan={1}></th>
                </tr>
              </thead>
              <tbody>
                { props.myForks.map((fork) => {
                  return (<tr key={fork.tokenId}>
                            <td>{fork.tokenId}</td>
                            <td>
                              <label htmlFor={fork.tokenId + "-basePrice"}>ETH: {formattedBasePriceFor(fork.tokenId)}</label>
                              <input id={fork.tokenId + "-basePrice"} type="number" className="form-control" value={rawBasePriceFor(fork.tokenId).toString()} onChange={(event) => {handleInputChange(fork.tokenId + "-basePrice", event.currentTarget.value)}} />
                            </td>
                            <td>
                              <label htmlFor={fork.tokenId + "-basePrice"}>   </label>
                              <input type="number" className="form-control" value={priceIncrementFor(fork.tokenId)} onChange={(event) => {handleInputChange(fork.tokenId + "-priceIncrement", event.currentTarget.value)}} />
                            </td>                        
                            <td>
                              <Button size="sm" variant="primary" onClick={() => props.onSave(fork.tokenId, input[fork.tokenId + "-basePrice"] as BigNumber, input[fork.tokenId + "-priceIncrement"] as number)}>{props.updating.includes(fork.tokenId) ?<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span>&nbsp;</span></> : <></>}{props.updating.includes(fork.tokenId) ? "Updating..." : "Update"}</Button>
                            </td>
                          </tr>);

                })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

}