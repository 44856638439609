import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import BalanceCard from "./cards/BalanceCard";
import ConfigureForkCard from "./cards/ConfigureForkCard";
import { CANVAS_CONTRACT_ADDRESS, SUB_ART_CONTRACT_ADDRESS } from "./Constsnts";
import { AutonomousSubArt, AutonomousSubArt__factory, CollectiveCanvas, CollectiveCanvas__factory } from "./typechain";
import { TypedEventFilter } from "./typechain/common";
import { TransferEvent } from "./typechain/ERC721";
import MyFork from "./util/MyFork";
import MyToken from "./util/MyToken";


export default function MembersOnly() {

  const [hasMinted, setHasMinted]                 = useState(false);
  const [myTokens, setMyTokens]                   = useState<MyToken[]>([]);
  const [myForks, setMyForks]                     = useState<MyFork[]>([]);
  const [mySubArtTokens, setMySubArtTokens]       = useState<MyToken[]>([]);
  const [rootBalance, setRootBalance]             = useState<BigNumber|null>(null);
  const [withdrawing, setWithdrawing]             = useState<number[]>([]);
  const [forkWithdrawing, setForkWithdrawing]     = useState<number[]>([]);
  const [subArtWithdrawing, setSubArtWithdrawing] = useState<number[]>([]);
  const [rootWithdrawing, setRootWithdrawing]     = useState<number[]>([]);
  const [updating, setUpdating]                   = useState<number[]>([]);

  const { active , library } = useWeb3React()

  useEffect(() => {
    if (active) {
      const contract     : CollectiveCanvas                = CollectiveCanvas__factory.connect(CANVAS_CONTRACT_ADDRESS, library);
      const filter       : TypedEventFilter<TransferEvent> = contract.filters.Transfer(null, null, null);
      const subArt       : AutonomousSubArt                = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library);
      const subArtFilter : TypedEventFilter<TransferEvent> = subArt.filters.Transfer(null, null, null);

      const listener = (event : any) => {updateBalance(library)};

      updateBalance(library);

      contract.on(filter, listener);
      subArt.on(subArtFilter, listener);

      return () => {contract.off(filter, listener); subArt.off(filter, listener);}
    }

  }, [active, library]);

  async function updateBalance(library : ethers.providers.Web3Provider) {
    const contract : CollectiveCanvas = CollectiveCanvas__factory.connect(CANVAS_CONTRACT_ADDRESS, library.getSigner());
    const subArt   : AutonomousSubArt = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library.getSigner());

    const myBalances     : MyToken[] = [];
    const subArtBalances : MyToken[] = [];

    for (var account of await library.listAccounts()) {
      var   balance    : number    = (await contract.balanceOf(account)).toNumber();
      const layerCount : number    = (await contract.layerCount()).toNumber();

      for (var i=0;myBalances.length<balance && i<layerCount;i++) {
        if ((await contract.ownerOf(i)) === account) {
          myBalances.push({balance: await contract.balanceOfToken(i), tokenId: i});
        }
      }

      const subArtBalance = (await subArt.balanceOf(account)).toNumber();

      for (var j=0;j<subArtBalance;j++) {
        const tokenId        = (await subArt.tokenOfOwnerByIndex(account, j)).toNumber();
        const balanceOfToken = await subArt.balanceOfToken(tokenId);

        subArtBalances.push({tokenId: tokenId, balance: balanceOfToken});
      }
    }

    const myForks : MyFork[] = await Promise.all(myBalances.map((myToken) => {
      const balance       = subArt.balanceOfParent(myToken.tokenId);
      const basePrice     = subArt.getForkBasePrice(myToken.tokenId);
      const priceInterval = subArt.getForkPriceIncrementInterval(myToken.tokenId);

      return Promise.all([balance, basePrice, priceInterval]).then<MyFork>(([balance, basePrice, priceInterval]) => {
        return {tokenId: myToken.tokenId, balance: balance, basePrice: basePrice, incrementInterval: priceInterval.toNumber()};
      });
    }));

    if (myBalances.find((token) => token.tokenId === 0)) {
      setRootBalance(await subArt.balanceOfRoot());
    } else {
      setRootBalance(null);
    }

    setMyTokens(myBalances);
    setMySubArtTokens(subArtBalances);
    setMyForks(myForks);
    setHasMinted(myBalances.length > 0 || subArtBalances.length > 0);
  }

  async function onWithdrawTokenRewards(tokenId: number, amount: BigNumber) {
    if (withdrawing.includes(tokenId)) return;
    setWithdrawing([...withdrawing, tokenId]);

    try {
      const contract : CollectiveCanvas = CollectiveCanvas__factory.connect(CANVAS_CONTRACT_ADDRESS, library.getSigner());
      const tx      = await contract.withdraw(BigNumber.from(tokenId), amount);
      
      await tx.wait(1);
      
      updateBalance(library);
      setWithdrawing(removeFromArray(withdrawing, tokenId));
    } catch (error) {
      console.error(error);
      setWithdrawing(removeFromArray(withdrawing, tokenId));
    }
  }

  async function onWithdrawForkRewards(tokenId: number) {
    if (forkWithdrawing.includes(tokenId)) return;
    setForkWithdrawing([...forkWithdrawing, tokenId]);

    try {
      const contract : AutonomousSubArt = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library.getSigner());
      const tx = await contract.withdrawForParent(tokenId);
      
      await tx.wait(1);
      
      updateBalance(library);
      setForkWithdrawing(removeFromArray(forkWithdrawing, tokenId));
    } catch (error) {
      console.error(error);
      setForkWithdrawing(removeFromArray(forkWithdrawing, tokenId));
    }
  }

  async function onWithdrawSubArtRewards(tokenId: number) {
    if (subArtWithdrawing.includes(tokenId)) return;
    setSubArtWithdrawing([...subArtWithdrawing, tokenId]);

    try {
      const contract : AutonomousSubArt = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library.getSigner());
      const tx = await contract.withdrawForToken(tokenId);
      
      await tx.wait(1);
      
      updateBalance(library);
      setSubArtWithdrawing(removeFromArray(subArtWithdrawing, tokenId));
    } catch (error) {
      console.error(error);
      setSubArtWithdrawing(removeFromArray(subArtWithdrawing, tokenId));
    }
  }

  async function onWithdrawRootRewards() {
    if (rootWithdrawing.includes(0)) return;
    setRootWithdrawing([...rootWithdrawing, 0]);

    try {
      const contract : AutonomousSubArt = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library.getSigner());
      const tx = await contract.withdrawForRoot();
      
      await tx.wait(1);
      
      updateBalance(library);
      setRootWithdrawing(removeFromArray(rootWithdrawing, 0));
    } catch (error) {
      console.error(error);
      setRootWithdrawing(removeFromArray(rootWithdrawing, 0));
    }
  }

  async function onSaveForkConfiguration(tokenId: number, basePrice: BigNumber, priceIncrementInterval: number) {
    if (updating.includes(tokenId)) return;
    setUpdating([...updating, tokenId]);

    try {
      const contract : AutonomousSubArt = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library.getSigner());
      const tx      = await contract.setForkConfiguration(tokenId, basePrice, BigNumber.from(priceIncrementInterval));
      
      tx.wait(1);
      
      setUpdating(removeFromArray(updating, tokenId));
    } catch (error) {
      console.error(error);
      setUpdating(removeFromArray(updating, tokenId));
    }
  }

  function removeFromArray<T>(array : T[], element : T) : T[] {
    var state = [...array];
    const index = state.indexOf(element);
    if (index !== -1) state.splice(index, 1);
    return state;
  }

  return (
    <>
    <hr/>
    {active && hasMinted &&
      <>
        <Row>
          <Col lg="6" md="12">
            <h1 className="display-3">Members Only</h1>
          </Col>
        </Row>
        <Row>
          <Col xl="6" lg="12">
            <BalanceCard header="Token rewards" onWithdraw={onWithdrawTokenRewards} withdrawing={withdrawing} tokens={myTokens}/>
          </Col>
          {rootBalance != null && <Col xl="6" lg="12">
            <BalanceCard header="Root rewards" onWithdraw={onWithdrawRootRewards} withdrawing={rootWithdrawing} tokens={[{tokenId: 0, balance: rootBalance}]}/>
          </Col>}
          <Col xl="6" lg="12">
            <BalanceCard header="Fork rewards" onWithdraw={onWithdrawForkRewards} withdrawing={forkWithdrawing} tokens={myForks}/>
          </Col>
          <Col xl="6" lg="12">
            <BalanceCard header="Sub art token rewards" onWithdraw={onWithdrawSubArtRewards} withdrawing={subArtWithdrawing} tokens={mySubArtTokens}/>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <ConfigureForkCard myForks={myForks} updating={updating} onSave={(tokenId, basePrice, incrementPriceInterval) => {onSaveForkConfiguration(tokenId, basePrice, incrementPriceInterval)}}/>
          </Col>
        </Row>
      </>
    }
    </>
  );

}