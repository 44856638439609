import { DocumentSnapshot } from "firebase/firestore";
import { FirestoreTopFork } from "./FirestoreTopFork";

export interface FirestoreTopForks {
  forks: FirestoreTopFork[];
}

export const converter = {
  toFirestore: (data: FirestoreTopForks) => data,
  fromFirestore: (snap: DocumentSnapshot) => snap.data() as FirestoreTopForks
}