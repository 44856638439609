import Element from './Element';

interface CircleProps {
  element: Element;
  onMouseOver: () => void;
  onSelected: () => void;
}

export default function Circle(props : CircleProps) {

  function rx() {
    return props.element.width.valueOf() / 2;
  }

  function ry() { 
    return props.element.height.valueOf() / 2;
  }

  function cx() {
    return props.element.x.valueOf() + props.element.width.valueOf() / 2;
  }

  function cy() {
    return props.element.y.valueOf() + props.element.height.valueOf() / 2;
  }

  function rotation() {
    return props.element.rotation ?? 0;
  }

  return (
    <ellipse cx={cx().toString()} 
            cy={cy().toString()} 
            rx={rx().toString()} 
            ry={ry().toString()}
            transform={"rotate(" + rotation() + " " + cx() + " " + cy() + ")"}
            fill={props.element.fillColor}
            stroke={props.element.strokeColor}
            strokeWidth={props.element.strokeWidth.valueOf()}
            onMouseOver={() => {props.onMouseOver()}}
            onMouseDown={() => {props.onSelected()}}
          />
  );

}
