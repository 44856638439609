import './App.css';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';
import { Container } from 'react-bootstrap';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import MembersOnly from './MembersOnly';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DynamicContent from './DynamicContent';
import StaticContent from './StaticContent';
import AutoConnectingInjectedProvider from './util/AutoConnectingInjectedProvider';
import ForkLeaderBoard from './ForkLeaderBoard';

library.add(faEthereum);

function getLibrary(provider: ethers.providers.ExternalProvider) {
  return new ethers.providers.Web3Provider(provider);
}

const NetworkWeb3ReactRoot = createWeb3ReactRoot("network");

function AppBody() {
  return (
    <>
      <DynamicContent />
      <ForkLeaderBoard />
      <StaticContent />
      <MembersOnly />
    </>
  )
}

function App() {

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <NetworkWeb3ReactRoot getLibrary={getLibrary}>
        <AutoConnectingInjectedProvider>
          <Router>
            <div className="main-content">
              <Container>
                <Routes>
                  <Route index element={<AppBody />} />
                  <Route path="fork/:forkId" element={<AppBody />} />
                </Routes>
              </Container>
            </div>
          </Router>                  
        </AutoConnectingInjectedProvider>
      </NetworkWeb3ReactRoot>
    </Web3ReactProvider>
  );
}

export default App;
