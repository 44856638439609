import { Card, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ACTIVATION_GAP_SIZE } from "./Constsnts";
import { HistoryEvent } from "./HistoryEvent";

interface HistoryLogProps {
  history: HistoryEvent[];
  selected: number | null;
  loading: boolean;
  forksEnabled: boolean;
  onSelectedHistoryIndex: (index: number) => void;
  onLoadMore: () => void;
}

export default function HistoryLog(props: HistoryLogProps) {  

  function getTimeStringFromEpoch(epochTime : number) : string {
    let date : Date = new Date(0);
    date.setUTCSeconds(epochTime);

    return date.toLocaleString();
  }

  function renderLink(item: HistoryEvent, index: number) {
    if (props.history.length > 0 && props.history[0].tokenId - props.history[index].tokenId > ACTIVATION_GAP_SIZE) {
      return (<h6><Link to={"/fork/" + props.history[index].tokenId}>{item.forkAdditions === 0 ? "Create fork" : item.forkAdditions}</Link></h6>);
    } else {
      return (<OverlayTrigger placement="right" delay={{ show: 10, hide: 400 }} overlay={<Tooltip id="button-tooltip">Tokens become eligible for forking after {ACTIVATION_GAP_SIZE} are subsequently minted</Tooltip>}>
                <h6>Pending</h6>
              </OverlayTrigger>);
    }
  }

  return (
    <Card style={{maxHeight: "400px", overflowY: "scroll"}}>
      <Card.Body>
        <Table size="sm" striped hover>
          <thead>
            <tr>
            <th>#</th>
            <th>Creator</th>
            {props.forksEnabled && <th>Fork length</th>}
            </tr>
          </thead>
          <tbody>
            {
              props.history.map((item, index) => {
                return (
                  <tr onClick={() => props.onSelectedHistoryIndex(index)} role="button">
                    <td><h6 className={index === (props.selected ?? -1) ? "" : "text-muted"}>{props.history[index].tokenId}</h6></td>
                    <td className="text-truncate"><div><h5>{item.author}</h5><h6 className="text-muted">{getTimeStringFromEpoch(item.timestamp)}</h6></div></td>
                    {props.forksEnabled && <td>{renderLink(item, index)}</td>}
                  </tr>
                );
              })
            }

            {props.history.length > 0 && props.history[props.history.length-1].tokenId !== 0 && 
            <tr onClick={() => {if (!props.loading) props.onLoadMore()}} role="button">
              <td><h6 className={"text-muted"}>...</h6></td>
              <td><h5>{props.loading ? "Loading..." : "Load more"}</h5></td>
              <td></td>
            </tr>}
          </tbody>
        </Table>
    </Card.Body>
  </Card>
  );

}