//import { Slider } from "@mui/material";
import faCodeFork from '../assets/icons/code-fork-solid.svg';
import { useEffect, useState } from "react";
import { Button, Card, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { ACTIVATION_GAP_SIZE } from '../Constsnts';
import { Link } from 'react-router-dom';
import playButton from '../assets/icons/play.svg';
import pauseButton from '../assets/icons/pause.svg';
import useInterval from '../util/useInterval';

interface HistoricalCanvasCardProps {
  svg: string | null;
  selectedLayer: number | null;
  isLoading: boolean;
  isFork: boolean;
}

export default function HistoricalCanvasCard(props: HistoricalCanvasCardProps) {

  const [isImageLoaded, setImageLoaded]           = useState("");
  const [baseSvgObject, setBaseSvgObject]         = useState<SVGSVGElement|null>(null);
  const [displaySvgObject, setDisplaySvgObject]   = useState<SVGSVGElement|null>(null);
  const [sliderMax, setSliderMax]                 = useState<number>(1);
  const [trimmedSvgUri, setTrimmedSvgUri]         = useState<string|null>(null);
  const [visibleLayerCount, setVisibleLayerCount] = useState<number|null>(null);

  const [isPlaying, setIsPlaying]                 = useState<boolean>(false);

  useEffect(() => {
    console.log("Parsing SVG...");
    if (props.svg == null) return;

    const match = props.svg.match("data:image\\/svg\\+xml;base64,(.+)");
    if (match == null) return;

    const encodedSection = match[1];
    const decodedSvg     = atob(encodedSection);
    const svgObject      = new DOMParser().parseFromString(decodedSvg, "image/svg+xml").getElementsByTagName('svg')[0];

    setBaseSvgObject(svgObject.cloneNode(false) as SVGSVGElement);
    setDisplaySvgObject(svgObject);
    setSliderMax(svgObject.children.length);
  }, [props.svg, props.isFork]);

  useEffect(() => {
    if (props.selectedLayer != null) {
      setVisibleLayerCount(props.selectedLayer + (props.isFork ? 0 : 1));
    } else if (props.isFork) {
      setVisibleLayerCount(null);
    }
  }, [props.isFork, props.selectedLayer]);

  useInterval(() => {
    setVisibleLayerCount(((visibleLayerCount ?? sliderMax) + 1) % sliderMax + 1);
  }, isPlaying ? 250 : null);

  useEffect(() => {
    if (baseSvgObject == null || displaySvgObject == null) return;

    baseSvgObject.replaceChildren(...(Array.from(displaySvgObject.children) as Node[]), ...(Array.from(baseSvgObject.children) as Node[]));
    displaySvgObject.replaceChildren(...(Array.from(baseSvgObject.children).slice(0, (visibleLayerCount ?? baseSvgObject.children.length))));

    const serialized = new XMLSerializer().serializeToString(displaySvgObject);
    setTrimmedSvgUri("data:image/svg+xml;base64," + btoa(serialized));
  }, [baseSvgObject, displaySvgObject, visibleLayerCount]);

  return (
    <Card>
      <Card.Body>
          <div style={isImageLoaded === props.svg && isImageLoaded !== "" ? {display: "none"} : {display: "block"}}>
            <ContentLoader viewBox="0 0 500 500">
              <rect x="0" y="0" rx="0" ry="10" width="100%" height="100%" />
            </ContentLoader>
          </div>

          <div style={isImageLoaded === props.svg && isImageLoaded !== "" ? {display: "block"} : {display: "none"}}>
            <Image src={trimmedSvgUri ?? ""} fluid={true} alt="Collective Canvas" onLoad={() => {setImageLoaded(props.svg ?? "")}}/>
            {!props.isFork && (sliderMax - (visibleLayerCount ?? sliderMax) >= ACTIVATION_GAP_SIZE) && <Link to={"/fork/" + ((visibleLayerCount ?? sliderMax) - 1)}><Image src={faCodeFork} width="30px" height="30px" style={{position: "absolute", top: "10px", right: "10px"}}/></Link>}
          </div>



          <div className="mt-2" style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
            <Button className='me-2' variant='outline-secondary' onClick={() => {setIsPlaying(!isPlaying);}}>{isPlaying ? <img src={pauseButton} alt='Play' /> : <img src={playButton} alt='Pause' />}</Button>
            <OverlayTrigger defaultShow={true}
                            placement="right"
                            overlay={<Tooltip>{(visibleLayerCount ?? sliderMax) - (props.isFork ? 0 : 1)}</Tooltip>
                          }>
              <input type="range" 
                    min={props.isFork ? 0 : 1}
                    max={sliderMax}
                    value={visibleLayerCount ?? sliderMax}
                    className="form-range"
                    onChange={(event) => {setVisibleLayerCount(Number.parseFloat(event.currentTarget.value))}} />
            </OverlayTrigger>
          </div>

      </Card.Body>
    </Card>
  );

}

