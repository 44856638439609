import { formatEther } from "ethers/lib/utils";
import { Card, Table, Image } from "react-bootstrap";
import { FirestoreTopFork } from "../firestore/FirestoreTopFork";

interface TopForksCardProps {
  title: string;
  topForks: FirestoreTopFork[];
  desc: boolean;
  funded: boolean;
}


export default function TopForksCard(props: TopForksCardProps) {

  return (
    <Card>
      <Card.Header>{props.title}</Card.Header>
      <Card.Body>
        <Table size="sm" striped hover responsive>
          <thead>
            <td></td>
            <td>Token ID</td>
            <td className="d-none d-sm-table-cell">Creator</td>
            <td>Contributions</td>
            <td>{props.funded ? "Total funds" : "Price to mint"}</td>
          </thead>
          <tbody>
            {
              props.topForks.sort((a, b) => a.layerCount - b.layerCount * (props.desc ? -1 : 1)).map((item, index) => {
                return (
                  <tr role="button" onClick={() => window.open("/fork/" + item.parentId, "_blank")?.focus()}>
                    <td><Image width={50} height={50} src={item.image} /></td>
                    <td><h6>{item.parentId}</h6></td>
                    <td className="d-none d-sm-table-cell"><h5>{item.creator}</h5></td>
                    <td><h6>{item.layerCount}</h6></td>
                    <td><h6>{formatEther(props.funded ? item.totalFunded : item.pricetoMint)}</h6></td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}