import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { Card, Col, Row } from "react-bootstrap";

interface ForkBasePriceCardProps {
  price: BigNumber;
  interval: number;
}

export default function ForkBasePriceCard(props: ForkBasePriceCardProps) {

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">Price increment on this fork</h6>
            <span className="h2 mb-0">{formatEther(props.price)} </span>
            <FontAwesomeIcon className="pb-1" icon={["fab", "ethereum"]} />
            <span className="h2 mb-0">/ {props.interval} </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );


}