import { useState } from 'react'

export default function useInputChange() {
  const [input, setInput] = useState<{[key: string]: any}>({});

  function handleInputChange(name: string, value: any) {
    setInput({...input, [name]: value});
  }

  /*
  const handleInputChange = (name: string, value: any) => setInput({
    ...input,
    [name]: value
  })
*/

  return [input, handleInputChange] as const;
}