import React, {useRef, useState, useEffect} from 'react';
import HoverBox from './decorators/HoverBox';
import Element from './elements/Element';
import Rectangle from './elements/Rectangle';
import Circle from './elements/Circle';
import { Tools } from './util/Tools';
import PolygonElement, { PolygonPointType } from './elements/PolygonElement';
import Polygon from './elements/Polygon';
import { getViewPortClickPosition } from './util/ViewPort';
import { optimize } from 'svgo';

interface SvgCanvasProps {
  elements          : Element[];
  currentTool       : Tools;
  backgroundUri     : string | null;
  onElementChange   : (index : number, element : Element) => void;
  onElementSelected : (index : number) => void;
  onElementAdded    : (element : Element) => void;
  onSerialized      : (serialized : string) => void;
}

export default function SvgCanvas(props : SvgCanvasProps) {

  const canvasElement                         = useRef<SVGSVGElement>(null);
  const layerElement                          = useRef<SVGGElement>(null);
  const [hoverElement, setHoverElement]       = useState<number | null>(null);
  const [creatingElement, setCreatingElement] = useState<Element | null>(null);

  const [scrollX, setScrollX]                 = useState(0);
  const [scrollY, setScrollY]                 = useState(0);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress)
  
    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }  
  });

  useEffect(() => {
    if (canvasElement.current != null) {
      const serialized = new XMLSerializer().serializeToString(canvasElement.current);
      const optimized  = optimize(serialized, {plugins: [{name: 'preset-default', params: {overrides: {convertPathData: false}}}]}).data;

      console.log("Original: " + serialized);
      console.log("Optimized: " + optimized);

      const layerMatch = optimized.match("^<svg .+?>(.+)<\\/svg>$");

      if (layerMatch != null && layerMatch.length === 2) {
        console.log("Layer group: " + layerMatch[1]);
        props.onSerialized(layerMatch[1]);
      } else {
        props.onSerialized("");
      }
    }
  }, [canvasElement, props.elements, props]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  });

  function getHoverElement() {
    const isEditing = props.elements.map((element) => element.editing).indexOf(true) !== -1;

    if (hoverElement != null && props.elements[hoverElement] !== undefined && props.currentTool === Tools.CURSOR && !isEditing) {
      return (<HoverBox element={props.elements[hoverElement]} 
                        canvas={canvasElement}
                        scrollX={scrollX}
                        scrollY={scrollY}
                        onSelect={() => props.onElementSelected(hoverElement)}
                        onMouseOut={() => setHoverElement(null)} 
                        onUpdate={(updated : Element) => {props.onElementChange(hoverElement, updated)}}
                        onEdit={() => {props.onElementChange(hoverElement, props.elements[hoverElement].withEditing(true))}}
                        />);
    } else {
      return <div></div>
    }
  }

  function handleCanvasMouseDown(event : React.MouseEvent) {
    const [x, y] = getViewPortClickPosition(canvasElement.current!, event);

    if (props.currentTool === Tools.RECTANGLE) {
      setCreatingElement(new Element("rect", x, y, 2, 2, 0, 0, "#000000", "#ffffff", 0));
    } else if (props.currentTool === Tools.CIRCLE) {
      setCreatingElement(new Element("circle", x, y, 2, 2, 0, 0, "#000000", "#ffffff", 0));
    } else if (props.currentTool === Tools.POLYGON && creatingElement == null) {
      setCreatingElement(PolygonElement.newDefault([{x: x, y: y, type: PolygonPointType.LINE, selected: false},
                                                    {x: x, y: y, type: PolygonPointType.LINE, selected: false}]));
    } else if (props.currentTool === Tools.POLYGON && creatingElement != null) {
      setCreatingElement(PolygonElement.newDefault([...(creatingElement as PolygonElement).points, 
                                                    {x: x, y: y, type: PolygonPointType.LINE, selected: false}]));
    } else {
      const editingElement = props.elements.map((element) => element.editing).indexOf(true);

      if (editingElement !== -1) {
        props.onElementChange(editingElement, props.elements[editingElement].withEditing(false));
      }
    }
  }

  function handleCanvasMouseMove(event : React.MouseEvent) {
    const [x, y] = getViewPortClickPosition(canvasElement.current!, event);

    if ((props.currentTool === Tools.RECTANGLE || props.currentTool === Tools.CIRCLE) && creatingElement != null) {
      const width  = x - creatingElement.x.valueOf();
      const height = y - creatingElement.y.valueOf();

      setCreatingElement(creatingElement.withUpdatedDimensions(width, height));
    } else if (props.currentTool === Tools.POLYGON && creatingElement != null) {  
      setCreatingElement(PolygonElement.newDefault([...(creatingElement as PolygonElement).points.slice(0, -1), 
                                                    {x: x, y: y, type: PolygonPointType.LINE, selected: false}]));
    }
  }

  function handleCanvasMouseUp(event : React.MouseEvent) {
    if ((props.currentTool === Tools.RECTANGLE || props.currentTool === Tools.CIRCLE) && creatingElement != null) {
      props.onElementAdded(creatingElement);
      setCreatingElement(null);
    }
  }

  function handlePolygonClosed() {
    if (props.currentTool === Tools.POLYGON && creatingElement != null) {
      props.onElementAdded(new PolygonElement((creatingElement as PolygonElement).points.slice(0, -1), false, true, 0, "#ffffff", "#000000", 1));
      setCreatingElement(null);
    }
  }

  function handleKeyPress(event: KeyboardEvent) {
    if (event.key === "Escape") {
      const editingElement = props.elements.map((element) => element.editing).indexOf(true);

      if (editingElement !== -1) {
        props.onElementChange(editingElement, props.elements[editingElement].withEditing(false));
      }
    }
  }

  function handleScroll(event: Event) {
    setScrollX((event.currentTarget as Window).scrollX);
    setScrollY((event.currentTarget as Window).scrollY);
  }

  return (
    <div className="mb-3">
      <svg ref={canvasElement} 
          width={"100%"}
          viewBox={"0 0 500 500"}
          onMouseDown={handleCanvasMouseDown}
          onMouseMove={handleCanvasMouseMove}
          onMouseUp={handleCanvasMouseUp}
          style={{backgroundSize:"auto", backgroundImage:"url(" + props.backgroundUri + "), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0iI2ZmZiI+PC9yZWN0Pgo8cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNGN0Y3RjciPjwvcmVjdD4KPHJlY3QgeD0iMTAiIHk9IjEwIiB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNGN0Y3RjciPjwvcmVjdD4KPC9zdmc+)"}}
          xmlns="http://www.w3.org/2000/svg">
        <g ref={layerElement}>
        {[...props.elements, creatingElement]
          .filter((element) => element != null)
          .map((element, index) => {

            switch (element?.type) {
              case "rect":
                return (<Rectangle key={index} 
                                  element={element} 
                                  onMouseOver={() => {setHoverElement(index)}}
                                  onSelected={() => props.onElementSelected(index)} />);

              case "circle":
                return (<Circle key={index}
                                element={element}
                                onMouseOver={() => {setHoverElement(index)}}
                                onSelected={() => props.onElementSelected(index)} />);
              case "polygon":
                return (<Polygon key={index}
                                 canvas={canvasElement.current!}
                                 element={(element as PolygonElement)}
                                 onMouseOver={() => {setHoverElement(index)}}
                                 onSelected={() => props.onElementSelected(index)}
                                 onClosed={handlePolygonClosed}
                                 onUpdate={(element) => props.onElementChange(index, element)} />);
              default:
                throw Error("unknown shape");
            }
        })}
        </g>
      </svg>

      {getHoverElement()}


    </div>
  );

}