import { BigNumber } from "@ethersproject/bignumber";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CanvasCard from "./cards/CanvasCard";
import { CANVAS_CONTRACT_ADDRESS, SUB_ART_CONTRACT_ADDRESS, TRIM_SVG_LAYERS } from "./Constsnts";
import { HistoryEvent } from "./HistoryEvent";
import HistoryLog from "./HistoryLog";
import MintButton from "./MintButton";
import { useEthNetwork } from "./NetworkConnector";
import PriceCard from "./cards/PriceCard";
import SvgDesigner from "./editor/SvgDesigner";
import { AutonomousSubArt, AutonomousSubArt__factory, CollectiveCanvas, CollectiveCanvas__factory } from "./typechain";
import { TypedEventFilter } from "./typechain/common";
import { TransferEvent } from "./typechain/ERC721";
import RevisionsCard from "./cards/RevisionsCard";
import FundedCard from "./cards/FundedCard";
import { useParams } from "react-router-dom";
import ForkedRevisionsCard from "./cards/ForkedRevisionsCard";
import HistoricalCanvasCard from "./cards/HistoricalCanvasCard";
import ForkBasePriceCard from "./cards/ForkBasePriceCard";


export default function DynamicContent() {
    
    const [active, library]                                 = useEthNetwork();
    const [history, setHistory]                             = useState<HistoryEvent[]>([]);
    const [svg, setSvg]                                     = useState<string|null>(null);
    const [trimmedSvg, setTrimmedSvg]                       = useState<string|null>(null);
    const [selectedHistory, setSelectedHistory]             = useState<number|null>(null);
    const [isLoading, setIsLoading]                         = useState(true);
    const [isLoadingPage, setIsLoadingPage]                 = useState(false);
    const [isEditable, setIsEditable]                       = useState(false);
    const [serialized, setSerialized]                       = useState("");
    const [currentPrice, setCurrentPrice]                   = useState(BigNumber.from(0));
    const [totalFunded, setTotalFunded]                     = useState(BigNumber.from(0));
    const [totalContributions, setTotalContributions]       = useState(0);
    const [totalSubContributions, setTotalSubContributions] = useState(0);
    const [forkBasePrice, setForkBasePrice]                 = useState(BigNumber.from(0));
    const [forkPriceInterval, setForkPriceInterval]         = useState(0);
    const [pages, setPages]                                 = useState(1);

    const { forkId } = useParams();

    useEffect(() => {
      setSelectedHistory(null);
    }, [forkId]);

    useEffect(() => {
      if (svg == null) return;

      if (forkId != null) {
        setTrimmedSvg(svg);
        return;
      }
      
      const match = svg.match("data:image\\/svg\\+xml;base64,(.+)");
      if (match == null) return;
    
      const encodedSection = match[1];
      const decodedSvg     = atob(encodedSection);
      const svgObject      = new DOMParser().parseFromString(decodedSvg, "image/svg+xml").getElementsByTagName('svg')[0];
    
      for (var i=Math.min(svgObject.children.length-1, TRIM_SVG_LAYERS);i>=0;i--) {
        svgObject.children[i].remove();
      }
    
      setTrimmedSvg("data:image/svg+xml;base64," + btoa(new XMLSerializer().serializeToString(svgObject)));
    }, [svg, forkId]);

    useEffect(() => {
      async function updateMainHistory() {
        console.log("Updating history");
        setIsLoadingPage(true);
  
        try {
          const contract       : CollectiveCanvas        = CollectiveCanvas__factory.connect(CANVAS_CONTRACT_ADDRESS, library);
          const subArt         : AutonomousSubArt        = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library);
          const layerCount     : number                  = (await contract.layerCount()).toNumber();
          const currentPrice   : Promise<BigNumber>      = contract.currentPriceToMint();
          const totalFunded    : Promise<BigNumber>      = contract.totalFunded();
          const totalSubFunded : Promise<BigNumber>      = subArt.totalFunded();
          const totalSubTokens : Promise<BigNumber>      = subArt.tokenCount();
          const svg                                      = contract.historicalImageAt(layerCount - 1).catch((error) => console.log(error));
          const historyPending : Promise<HistoryEvent>[] = [];
  
          for (var i=Math.max(0, layerCount-(50 * pages));i<layerCount;i++) {
            // eslint-disable-next-line no-loop-func
            const historyEvent = history.find(event => event.tokenId === i && event.parentId === -1);

            if (historyEvent) {
              console.log("Found existing event");
              historyPending.push(Promise.resolve(historyEvent));
            } else {
              const tokenId         = i;
              const author          = contract.creatorAt(tokenId);
              const authorName      = author.then((resolvedAuthor) => library.lookupAddress(resolvedAuthor)).catch((error) => console.error(error));
              const timestamp       = contract.timestampAt(tokenId).then((timestampString) => timestampString.toNumber());
              const forkedAdditions = subArt.layerCount(tokenId);
    
              historyPending.push(Promise.all([author, authorName, timestamp, forkedAdditions]).then<HistoryEvent>(([author, authorName, timestamp, forkedAdditions]) => {return {author: authorName ?? author, timestamp: timestamp, tokenId: tokenId, forkAdditions: forkedAdditions.toNumber(), parentId: -1}}));
            }
          }

          const resolvedHistory : HistoryEvent[] = await Promise.all(historyPending);

          console.log("Total funded: " + totalFunded);
          console.log("Selected history: " + selectedHistory);

          setHistory(resolvedHistory.reverse());
          setCurrentPrice(await currentPrice);
          setTotalFunded((await totalFunded).add(await totalSubFunded));
          setTotalContributions(layerCount);
          setTotalSubContributions((await totalSubTokens).toNumber());
          setSvg((await svg) ?? null);
          setIsEditable(true);
          setIsLoading(false);          
          setIsLoadingPage(false);
        } catch (error) {
          console.error(error);
        }
      }

      async function updateForkHistory() {
        console.log("Updating fork history");
        setIsLoadingPage(true);

        try {
          const parentId               : BigNumber               = BigNumber.from(forkId);
          const parentContract         : CollectiveCanvas        = CollectiveCanvas__factory.connect(CANVAS_CONTRACT_ADDRESS, library);
          const contract               : AutonomousSubArt        = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library);
          const layerCount             : number                  = (await contract.layerCount(parentId)).toNumber();
          const currentPrice           : Promise<BigNumber>      = contract.currentPriceToMint(parentId);
          const totalFunded            : Promise<BigNumber>      = contract.totalForkFunded(parentId);
          const svg                    : Promise<string>         = contract.imageForFork(parentId);
          const basePrice              : Promise<BigNumber>      = contract.getForkBasePrice(parentId);
          const priceIncrementInterval : Promise<BigNumber>      = contract.getForkPriceIncrementInterval(parentId);
          const isForkEditable         : Promise<Boolean>        = contract.isForkActivated(parentId);
          const historyPending         : Promise<HistoryEvent>[] = [];

          const parentAuthor     = parentContract.creatorAt(parentId);
          const parentAuthorName = parentAuthor.then((resolvedParentAuthor) => library.lookupAddress(resolvedParentAuthor)).catch((error) => console.error(error));
          const parentTimestamp  = parentContract.timestampAt(parentId).then((timestampString) => timestampString.toNumber());
          const parentRecord     = Promise.all([parentAuthor, parentAuthorName, parentTimestamp]).then<HistoryEvent>(([parentAuthor, parentAuthorName, parentTimestamp]) => {return {author: parentAuthorName ?? parentAuthor, timestamp: parentTimestamp, tokenId: 0, forkAdditions: 0, parentId: parentId.toNumber()}});

          for (var i=Math.max(0, layerCount-(50 * pages));i<layerCount;i++) {
            // eslint-disable-next-line no-loop-func
            const historyEvent = history.find(event => event.tokenId === (i + 1) && event.parentId === parentId.toNumber());

            if (historyEvent) {
              console.log("Found existing event");
              historyPending.push(Promise.resolve(historyEvent));
            } else {
              const layerIndex = i;
              const layer      = contract.layerAt(parentId, layerIndex);
              const authorName = layer.then((layer) => library.lookupAddress(layer.creator)).catch((error) => console.error(error));

              historyPending.push(Promise.all([layer, authorName]).then<HistoryEvent>(([layer, authorName]) => {return {author: authorName ?? layer.creator, timestamp: layer.timestamp.toNumber(), tokenId: layerIndex + 1, forkAdditions: 0, parentId: parentId.toNumber()}}));
            }
          }

          const [resolvedForkHistory, resolvedParentRecord] = await Promise.all([Promise.all(historyPending), parentRecord]);
          const resolvedHistory     : HistoryEvent[] = [resolvedParentRecord, ...resolvedForkHistory];

          setHistory(resolvedHistory.reverse());
          setCurrentPrice(await currentPrice);
          setTotalFunded(await totalFunded);
          setSvg(await svg);
          setForkPriceInterval((await priceIncrementInterval).toNumber());
          setForkBasePrice(await basePrice);
          setTotalContributions(layerCount);
          setIsEditable((await isForkEditable).valueOf());
          setIsLoading(false);          
          setIsLoadingPage(false);
        } catch (error) {
          console.error(error);
        }
      }
  
      console.log("Running, active: " + active);
  
      if (active && forkId == null) {
        const contract : CollectiveCanvas                = CollectiveCanvas__factory.connect(CANVAS_CONTRACT_ADDRESS, library);
        const filter   : TypedEventFilter<TransferEvent> = contract.filters.Transfer(null, null, null);
        const listener = (event : any) => {updateMainHistory()};
  
        updateMainHistory();
  
        contract.on(filter, listener);
  
        return () => {contract.off(filter, listener);}
      } else if (active) {
        const contract : AutonomousSubArt                = AutonomousSubArt__factory.connect(SUB_ART_CONTRACT_ADDRESS, library);
        const filter   : TypedEventFilter<TransferEvent> = contract.filters.Transfer(null, null, null);
        const listener = (event : any) => {updateForkHistory()};
  
        updateForkHistory();
  
        contract.on(filter, listener);
  
        return () => {contract.off(filter, listener);}
      }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, library, pages, forkId]);
  

    return (
      <>
        <Row className="align-items-center mt-5 mb-5">
          <Col md="6">
            { forkId == null && <>
              <h1 className="display-2 text-md-start text-center align-middle">Welcome to autonomous art.</h1>
              <p className="lead text-center text-md-start text-muted mb-6 mb-lg-8">Mint an NFT by making a visual contribution to a collective work.</p>          
            </>}

            { forkId != null && <>
              <h1 className="display-2 text-md-start text-center align-middle">Autonomous Art #{forkId}</h1>
              <p className="lead text-center text-md-start text-muted mb-3 mb-lg-4">This is a fork of <a href="/">Autonomous Art</a> at contribution #{forkId}.</p>          

              <p className="lead text-center text-md-start text-muted mb-4 mb-lg-8">Mint an Autonomous Sub Art NFT by making a visual contribution to the collective work of this fork.</p>          
            </>}
          </Col>
          <Col md="6">
            <CanvasCard svg={trimmedSvg} isLoading={isLoading} />
          </Col>
        </Row>
        <Row>
          <Col md={forkId == null ? "3" : "3"}>
            <PriceCard price={currentPrice}/>
          </Col>
          <Col md={forkId == null ? "3" : "3"}>
            <RevisionsCard count={totalContributions} />
          </Col>
          {forkId == null && <Col md="3"><ForkedRevisionsCard count={totalSubContributions}/></Col>}
          {forkId != null && <Col md="3"><ForkBasePriceCard price={forkBasePrice} interval={forkPriceInterval}/></Col>}
          <Col md={forkId == null ? "3" : "3"}>
            <FundedCard count={totalFunded}/>
          </Col>
        </Row>
        <Row>
          <Col lg={{span: 6}} md={{span: 12}}>
              <HistoricalCanvasCard isFork={forkId != null} svg={svg} isLoading={isLoading} selectedLayer={selectedHistory != null ? history[selectedHistory].tokenId : null} />
          </Col>  
          <Col lg={{span: 6, order: 'last'}} md={{span: 12, order: 'first'}} xs={{order: 'first'}} >
            <h1 className="display-3">Explore the history</h1>
            <p className="fs-2 text-muted">Each contribution combines to form the whole.</p>
            <HistoryLog forksEnabled={forkId == null} history={history} onSelectedHistoryIndex={(index) => setSelectedHistory(index)} selected={selectedHistory} loading={isLoadingPage} onLoadMore={() => setPages(pages+1)}/>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <h1 className="display-3 text-md-start">Make your mark {forkId != null && "on this fork"}</h1>
            <p className="lead text-muted">Start drawing, add to the art.</p>
          </Col>
        </Row>
        <SvgDesigner onSerialized={(serialized) => setSerialized(serialized)} backgroundUri={trimmedSvg != null ? trimmedSvg : "null"} />
        <Row>
          <Col lg={{span: 6, offset: 1}}>
            <MintButton serializedLayer={serialized} parentId={forkId} isMintable={isEditable} />
          </Col>
        </Row>
      </>
    );

}